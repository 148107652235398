<template>
	<app-breadcrumbs container="ul" class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
		<li slot-scope="{to, label, utils, index}" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">

			<meta itemprop="position" :content="index + 1" />
			<router-link 
				:to="to.path" 
				exact 
				v-if="to.path == '/'"
				itemscope
				itemtype="http://schema.org/Thing"
				:itemid="to.path"
				itemprop="item"
			>
				<span itemprop="name">
					{{label}}
				</span>
			</router-link>

			<router-link 
				:to="to.path + '/'" 
				exact 
				v-else
				itemscope
				itemtype="http://schema.org/Thing"
				:itemid="to.path + '/'"
				itemprop="item">
				<span itemprop="name">
					{{label}}
				</span>
			</router-link>
		</li>

		<template slot="current" slot-scope="{to, label, utils}">
			<div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
				<meta itemprop="position" :content="count" />
				<router-link itemscope itemtype="http://schema.org/Thing" itemprop="item" :to="$route.matched[0].path + '/'" :itemid="$route.matched[0].path + '/'">
					<span itemprop="name">
						{{label}}
					</span>
				</router-link>
			</div>
		</template>
	</app-breadcrumbs>

</template>

<style lang="sass">
	@import '@/assets/sass/BreadCrumbs'
</style>

<script>
	export default {
		data: () => ({
			count: 0
		}),
		beforeMount(){
			this.count = this.$route.path.split("/").length - 1;
		}
	}
</script>